table {
	width: 100%;
}

.squaresides {
	border-radius: 0 !important;
}

.pagination {
	justify-content: space-between;
}
